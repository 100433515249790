import React from 'react';
import styled from 'styled-components';
import { Location } from '@reach/router';

import { D } from '@dayetopia/library';

interface Props {
  step: string;
  heading: string;
  matchUrl: RegExp;
}

export default function CheckoutHeaderNavigationItem({
  step,
  heading,
  matchUrl,
}: Props) {
  return (
    <Location>
      {({ location }) => {
        const selected = matchUrl.test(location.pathname);
        return (
          <Container selected={selected}>
            <D.P
              mods={[10, 'center']}
              style={{ marginBottom: 0, letterSpacing: '1.31px' }}
            >
              {step}
            </D.P>
            <D.P
              mods={[14, 'center']}
              style={{
                fontWeight: selected ? 900 : 100,
                letterSpacing: '1.31px',
              }}
            >
              {heading}
            </D.P>
          </Container>
        );
      }}
    </Location>
  );
}

const Container = styled.div`
  display: flex;
  flex: 1;
  height: 85px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-bottom: ${(props: { selected: boolean }) =>
    props.selected ? '2px solid #003a1b' : '2px solid #cae2c3'};
  @media only screen and (max-width: 768px) {
    height: 65px;
  }
`;
