import React, { useContext, useState, useMemo, useEffect, useRef } from 'react';
import styled from 'styled-components';

import { D } from '@dayetopia/library';
import IconArrow from '../images/arrow.svg';
import Summary from './CheckoutSummary';
import { Context } from './Context';
import useBoxPrice from './useBoxPrice';

export default function OrderSummaryMobile() {
  const { state } = useContext(Context);
  const { selectedBox, checkoutDiscountAmount } = state;
  const [open, setOpen] = useState(false);
  const [fullViewHeight, setFullViewHeight] = useState(0);
  const [hideSummary, setHideSummary] = useState(false);
  const [isIOS, setIsIOS] = useState(false);
  const [price, setPrice] = useState(0);
  const [totalPrice, setTotalPrice] = useState('');
  const ref = useRef(null);

  function handleOpen() {
    setOpen(!open);
  }

  useEffect(() => {
    const iOS =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    setIsIOS(iOS);
  }, []);

  // always show summary if summary is open
  useEffect(() => {
    if (open) setHideSummary(false);
  }, [open]);

  // ANDROID
  // Hide the order summary on android when keyboard is open
  // Does not work on iOS as innerHeight does not change i.e.
  // keyboard is overlay
  useEffect(() => {
    if (isIOS) return;
    const height = window.innerHeight;
    setFullViewHeight(height);
  }, []);

  useEffect(() => {
    if (isIOS) return;
    const newHeight = window.innerHeight;
    if (!open && newHeight < fullViewHeight - 60) {
      setHideSummary(true);
    } else {
      setHideSummary(false);
    }
  });

  // IOS
  // Hide the summary if summary is close to the top of page
  useEffect(() => {
    if (!isIOS) return;
    const orderSummary = ref.current;
    const initialTopDistance = orderSummary.getBoundingClientRect().top;
    window.addEventListener('scroll', () => {
      checkSummaryMoved(initialTopDistance);
    });
  }, []);

  function checkSummaryMoved(initialTopDistance) {
    if (!isIOS) return;
    const orderSummary = ref.current;
    if (!orderSummary) return;
    if (open) return setHideSummary(false);
    const newTopDistance = orderSummary.getBoundingClientRect().top;
    const keypadOpen = newTopDistance + 20 < initialTopDistance;
    if (keypadOpen) {
      setHideSummary(true);
    } else {
      setHideSummary(false);
    }
  }

  useEffect(() => {
    if (!selectedBox) return;
    useBoxPrice({ id: selectedBox.id, count: 1 }).then(res => {
      setPrice(res);
      setTotalPrice(res.toFixed(2));
      if (checkoutDiscountAmount > 0) {
        let discountPrice = price - checkoutDiscountAmount;
        setTotalPrice(discountPrice.toFixed(2));
      }
    });
  }, []);

  useEffect(() => {
    if (checkoutDiscountAmount > 0) {
      let discountPrice = price - checkoutDiscountAmount;
      setTotalPrice(discountPrice.toFixed(2));
    } else {
      setTotalPrice(price.toFixed(2));
    }
  }, [price, checkoutDiscountAmount]);

  return useMemo(() => {
    return (
      <>
        <FullPageContainer open={open} onClick={handleOpen} />
        <Container
          hide={hideSummary}
          open={open}
          onClick={handleOpen}
          ref={ref}
        >
          <TopContainer open={open}>
            <ContainerText>
              <D.P
                mods={[14, 'spaced1', 'caps', 'bold']}
                style={{ marginBottom: 4 }}
              >
                Order Summary
              </D.P>
              <D.P mods={[14]}>
                Total:{' '}
                <D.Span mods={['bold']} style={{ marginRight: 5 }}>
                  £{totalPrice}
                </D.Span>
                {!open && (
                  <D.Span mods={['gold2']}>Open for more details</D.Span>
                )}
              </D.P>
            </ContainerText>
            <IconArrowStyled open={open} />
          </TopContainer>
          <BottomContainer>
            <Summary />
          </BottomContainer>
        </Container>
      </>
    );
  }, [totalPrice, open, hideSummary]);
}

interface IStyle {
  open: boolean;
}

const FullPageContainer = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  background-color: rgba(0, 0, 0, 0.2);
  display: ${(props: IStyle) => (props.open ? 'block' : 'none')};
`;

interface IC {
  open: boolean;
  hide: boolean;
}

const Container = styled.div`
  position: fixed;
  bottom: -1px;
  transition: all 0.3s ease-in-out;
  height: ${(props: IC) => (props.open ? '455px' : '90px')};
  background-color: ${(props: IStyle) => (props.open ? '#fefce8' : '#faead6')};
  &:hover {
    cursor: pointer;
  }
  z-index: ${props => (props.hide ? '-2' : 101)};
`;

const TopContainer = styled.div`
  height: 90px;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
`;

const ContainerText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

const BottomContainer = styled.div`
  padding: 0px 20px 0 20px;
`;

const IconArrowStyled = styled(IconArrow)`
  width: 22px;
  height: 12px;
  transition: transform 0.3s ease-in-out;
  transform: rotate(${props => (props.open ? '360deg' : '180deg')});
`;
