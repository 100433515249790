import React, { useContext, useEffect, useState } from 'react';
import { navigate } from 'gatsby';

import { Checkout, D } from '@dayetopia/library';
import CheckoutLayout from '../components/CheckoutLayout';
import useLogin from '../components/useLogin';
import { Context } from '../components/Context';
import * as apiKeys from '../../keys.json';
import getOmsUrl from '../../getOmsUrl';
import styled from 'styled-components';
import useBoxPrice from '../components/useBoxPrice';
import { CountryCode } from '../types';
import useFetch from '../components/useFetch';

export default function CheckoutRoot() {
  const { state, dispatch } = useContext(Context);
  const [price, setPrice] = useState(0);
  const runLogin = useLogin();
  const omsUrl = window ? getOmsUrl(window.location.hostname) : '';
  const {
    loggedIn,
    firebaseAuth,
    checkoutDiscountAmount,
    selectedPlan,
    selectedBox,
  } = state;
  const fetch = useFetch();

  useEffect(() => {
    if (!selectedBox) return;
    useBoxPrice({ id: selectedBox.id, count: 1 }).then(res => {
      setPrice(res);
    });
  }, []);

  useEffect(() => {
    if (loggedIn) {
      submitQuestions();
    }
  }, [loggedIn]);

  function clearState() {
    dispatch({ type: 'CLEAR-STATE-AND-STORAGE' });
  }

  function updateStateAfterAccountCreate() {
    dispatch({ type: 'setLoggedIn', data: true });
    const message = 'Account successfully created';
    dispatch({ type: 'setStatusMessage', data: message });
  }

  async function submitQuestions() {
    console.log('submitQuestions');
    try {
      const url = '/cedarwood/questionnaire';
      const body = state.questionAnswers;
      console.log(body);
      await fetch.post(url, body);
    } catch (err) {
      console.log('err', Error(err));
    }
  }

  function setCheckoutDiscountAmount(discountAmount: number) {
    dispatch({ type: 'setCheckoutDiscountAmount', data: discountAmount });
  }

  function goToQuestionnaire() {
    navigate('/questionnaire');
  }

  const isCreateAccPage =
    window.location.pathname === '/checkout/create-account';

  if (!selectedBox && !isCreateAccPage) {
    return (
      <CheckoutLayout>
        <PStyled>
          you do not have any box selected, please fill out the questionnaire
          and select one of the recommended boxes
        </PStyled>

        <ButtonStyled onClick={goToQuestionnaire}>
          Go to questionnaire
        </ButtonStyled>
      </CheckoutLayout>
    );
  }

  return (
    <CheckoutLayout>
      <Checkout
        platform={'CRE'}
        currency={{ letterCode: 'GBP', symbol: '£' }}
        loggedIn={loggedIn}
        clearState={clearState}
        runLogin={runLogin}
        firebaseAuth={firebaseAuth}
        updateStateAfterAccountCreate={updateStateAfterAccountCreate}
        checkoutDiscountAmount={checkoutDiscountAmount}
        setCheckoutDiscountAmount={setCheckoutDiscountAmount}
        selectedBox={selectedBox}
        apiKeys={apiKeys}
        selectedPlan={selectedPlan}
        omsUrl={omsUrl}
        navigate={navigate}
        price={price}
        country={CountryCode.GB}
      />
    </CheckoutLayout>
  );
}

const PStyled = styled(D.P)`
  margin: 50px;
`;

const ButtonStyled = styled(D.Button)`
  margin-left: 50px;
`;
