import React, { useContext } from 'react';
import styled from 'styled-components';

import CheckoutProgressItem from './CheckoutProgressItem';
import { Context } from './Context';

export default function CheckoutProgress() {
  const { state } = useContext(Context);
  const { desktop } = state;

  return (
    <Container>
      <CheckoutProgressItem
        step={'STEP 1'}
        heading={'ACCOUNT'}
        matchUrl={/\/create-account|\/login/}
      />
      <CheckoutProgressItem
        step={'STEP 2'}
        heading={desktop ? 'SHIPPING & DELIVERY' : 'DELIVERY'}
        matchUrl={/\/shipping/}
      />
      <CheckoutProgressItem
        step={'STEP 3'}
        heading={desktop ? 'BILLING & PAYMENT' : 'PAYMENT'}
        matchUrl={/\/billing/}
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 85px;
  @media only screen and (max-width: 768px) {
    height: 65px;
    padding: 0;
  }
`;
