import React from 'react';
import styled from 'styled-components';

import { D } from '@dayetopia/library';
import Summary from './CheckoutSummary';
import CheckoutProgress from './CheckoutProgress';
import SummaryMobile from './CheckoutSummaryMobile';

interface Props {
  children: any;
}
export default function CheckoutLayout({ children }: Props) {
  return (
    <Container>
      <ContainerInner>
        <Left>
          <CheckoutProgress />
          <ScrollWrapper>
            <FormWrapper>{children}</FormWrapper>
          </ScrollWrapper>
        </Left>
        <D.Desktop>
          <Right>
            <SummaryContainer>
              <Summary />
            </SummaryContainer>
          </Right>
        </D.Desktop>
      </ContainerInner>
      <D.Tablet>
        <SummaryMobile />
      </D.Tablet>
    </Container>
  );
}

const Container = styled.div`
  height: 100vh;
  overflow: hidden;
`;

const ContainerInner = styled.div`
  display: flex;
  flex: 1;
  background: #eaf5e8;
  animation: fadein 0.8s;
  justify-content: space-between;
  height: 100%;
`;

const Left = styled.div`
  flex: 1;
`;

interface ScrollWrapper {
  window: any;
}

const ScrollWrapper = styled.div`
  overflow: scroll;
  height: 100%;
  padding: 60px 0 100px 0;
  @media (max-width: 1200px) {
    padding: 30px 20px 100px 20px;
  }
`;

const FormWrapper = styled.div`
  max-width: 520px;
  margin-left: 120px;
  padding: 0 3px;
  @media (max-width: 1200px) {
    margin-left: 0;
  }
`;

const Right = styled.div`
  /* flex: 1; */
`;

const SummaryContainer = styled.div`
  background: #fefcea;
  width: 436px;
  padding: 40px 60px 0 50px;
  height: 100%;
`;
